import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { BucketManagerComponent } from './bucket-manager/bucket-manager.component';
import { MaterialModule } from '../material.module';

@NgModule({
  declarations: [
    AdminComponent,
    BucketManagerComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    AdminRoutingModule,
  ]
})
export class AdminModule { }
