import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';

import { BucketService, Bucket } from '../../services';

import { Observable } from 'rxjs';




@Component({
  selector: 'app-bucket-manager',
  templateUrl: './bucket-manager.component.html',
  styleUrls: ['./bucket-manager.component.css']
})
export class BucketManagerComponent implements OnInit {

  selectedBucket: Bucket;

  currentBuckets: Bucket[];

  bucketDetailsGroup: FormGroup;

  bucketNameControl: FormControl;

  allBuckets: Observable<Bucket[]>;

  constructor(
    private _formBuilder: FormBuilder,
    private bucketService: BucketService,
    ) {

    this.currentBuckets = [
      { id: '', name: 'Paragliden', description: '', category: '' },
      { id: '',  name: 'Bungee Jumping', description: '', category: '' },
    ];
    this.selectedBucket = this.currentBuckets[0];
    this.allBuckets = bucketService.getBuckets();
   }

  ngOnInit() {
    this.bucketNameControl = new FormControl('');

    this.bucketDetailsGroup = this._formBuilder.group({
      bucketName: ['', Validators.required],
      firstCtrl: ['', Validators.required],
  });
  }

}
