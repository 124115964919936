import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BucketlistComponent } from './bucketlist/bucketlist.component';
import { ExperienceMarketplaceComponent } from './experience-marketplace/experience-marketplace.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './user/settings.component';
import { AdminComponent } from './admin/admin.component';

const routes: Routes = [
 // { path: 'settings', component: SettingsComponent },
  { path: 'profile/:id', component: ProfileComponent },
  { path: 'bucket-list', component: BucketlistComponent },
  { path: 'experience-marketplace', component: ExperienceMarketplaceComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
//    {enableTracing: true}
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
