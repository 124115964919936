import { NgModule } from '@angular/core';
import { Route, Routes, RouterModule } from '@angular/router';

import { BucketManagerComponent } from './bucket-manager/bucket-manager.component';
import { AdminComponent } from './admin.component';
import { PageNotFoundComponent } from '../page-not-found/page-not-found.component';

const routes: Routes = [
  { path: 'admin', component: AdminComponent, children: [
    { path: '', pathMatch: 'full', redirectTo: 'bucket-manager' },
    { path: 'bucket-manager', component: BucketManagerComponent },
    { path: '**', component: PageNotFoundComponent },
  ] }
];

@NgModule({
  imports: [RouterModule.forChild(routes,
//    {enableTracing: true}
    )],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
